import { Fragment } from "react";
import { Box, Button, Checkbox, Stack } from "@mui/material";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getExpandedRowModel,
  Row,
  ColumnDef,
  TableOptions,
  Cell as TCell,
  Table as TTable,
} from "@tanstack/react-table";
import Cell from "./Cell";
import Header from "./Header";
import { Medium, Strong } from "../Text";
import { useUser } from "../../hooks/useUser";
import { Delete } from "@mui/icons-material";
import { DialogCta } from "../DialogCta";
import { Trans, useTranslation } from "react-i18next";

export type TableData = {
  id: string;
};

export interface RowExpandedProps<TDataType extends TableData> {
  row: Row<TDataType>;
  index: number;
}

export const MetricHeader = (props: { label: string; unit: string }) => (
  <Stack alignItems="flex-end">
    <Strong spacing="dense">{props.label}</Strong>
    <Medium spacing="dense" size={12}>
      ({props.unit})
    </Medium>
  </Stack>
);

export interface TableProps<TDataType extends TableData> {
  id: string;
  context: string;
  columns: ColumnDef<TDataType>[];
  data?: TDataType[];
  options?: Partial<TableOptions<TDataType>> & {
    handleDeleteRows?: (table: TTable<TDataType>) => void;
  };
  components?: {
    RowExpanded?: React.ComponentType<RowExpandedProps<TDataType>>;
  };
}

export const Table: <TDataType extends TableData>(
  props: TableProps<TDataType>,
) => JSX.Element | null = (props) => {
  const { columns, data = [], components, context, options = {} } = props;
  const { RowExpanded } = components ?? {};
  const [{ userId }] = useUser();
  const { t } = useTranslation();
  const table = useReactTable({
    ...options,
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => Boolean(RowExpanded) && userId !== "unknown",
    columnResizeMode: "onChange",
    initialState: {
      // Disable the expand functionality for display data
      columnVisibility: {
        expand: userId !== "unknown",
      },
    },
  });

  return !data ? null : (
    <Stack sx={{ overflowY: "hidden", width: "100%" }} gap={2}>
      {(options.enableRowSelection || options.enableMultiRowSelection) && (
        <Stack direction="row" justifyContent="space-between">
          <DialogCta
            anchor={(props) => (
              <Button
                {...props}
                variant="outlined"
                color="error"
                size="small"
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
              >
                <Delete />
              </Button>
            )}
            actions={({ close }) => (
              <>
                <Button variant="secondary" onClick={close}>
                  {t("nevermind")}
                </Button>
                <Button
                  variant="primary"
                  onClick={async () => {
                    await options.handleDeleteRows?.(table);
                    close();
                  }}
                >
                  {t("confirm.delete.yes")}
                </Button>
              </>
            )}
            title={t("confirm.delete.title", { type: context })}
          >
            <Trans
              i18nKey="confirm.delete.type"
              components={[<p key="0" />, <p key="1" />]}
              values={{ type: context }}
            />
          </DialogCta>
        </Stack>
      )}
      <Stack direction="row" sx={{ width: "100%" }}>
        {(options.enableRowSelection || options.enableMultiRowSelection) && (
          <Header align={"center"} sx={{ minWidth: 48, width: 48 }}>
            <Checkbox
              size="small"
              checked={table.getIsAllRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </Header>
        )}
        {table.getHeaderGroups().map((headerGroup) => (
          <Fragment key={headerGroup.id}>
            {headerGroup.headers.map((header, headerIndex) => (
              <Header
                key={header.id}
                align={
                  header.column.columnDef.meta?.align ??
                  header.column.columnDef.meta?.type === "number"
                    ? "right"
                    : "left"
                }
                sx={{ minWidth: header.column.columnDef.minSize, width: header.column.getSize() }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </Header>
            ))}
          </Fragment>
        ))}
      </Stack>
      <Stack sx={{ width: "100%" }}>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <Fragment key={row.id}>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                cursor: row.getCanExpand() ? "pointer" : "default",
                "&:hover": row.getCanExpand()
                  ? {
                      "& > *": {
                        backgroundColor: "grey.200",
                      },
                    }
                  : {},
              }}
              onClick={row.getCanExpand() ? row.getToggleExpandedHandler() : () => null}
            >
              {(row.getCanSelect() || row.getCanMultiSelect()) && (
                <Box
                  component={Cell}
                  sx={{
                    borderBottom: row.getIsExpanded() ? "1px solid" : "none",
                    borderColor: "grey.200",
                    filter: row.getIsExpanded() ? "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))" : "",
                    minWidth: 48,
                    width: 48,
                  }}
                  align="center"
                  alt={rowIndex % 2 === 0}
                >
                  <Checkbox
                    size="small"
                    checked={row.getIsSelected()}
                    onChange={row.getToggleSelectedHandler()}
                  />
                </Box>
              )}
              {row.getVisibleCells().map((cell, cellIndex, cells) => (
                <Box
                  component={Cell}
                  key={cell?.id}
                  sx={{
                    borderBottom: row.getIsExpanded() ? "1px solid" : "none",
                    borderColor: "grey.200",
                    filter: row.getIsExpanded() ? "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))" : "",
                    minWidth: cell && cell.column.columnDef.minSize,
                    width: cell && cell.column.getSize(),
                  }}
                  align={cell && cell.column.columnDef.meta?.align}
                  alt={rowIndex % 2 === 0}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Box>
              ))}
            </Stack>
            {row.getCanExpand() && row.getIsExpanded() && RowExpanded && (
              <RowExpanded row={row} index={rowIndex} />
            )}
          </Fragment>
        ))}
      </Stack>
      {/* <Stack>
        {table.getFooterGroups().map((footerGroup) => (
          <Stack key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <Stack key={header.id} width="100%" minWidth={header.column.getSize()}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.footer, header.getContext())}
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack> */}
    </Stack>
  );
};

const TableCell = <TDataType,>({
  row,
  cell,
  rowIndex,
  children,
}: {
  row: Row<TDataType>;
  cell?: TCell<TDataType, unknown>;
  rowIndex: number;
  children: React.ReactNode;
}) => (
  <>
    <Box
      component={Cell}
      key={cell?.id}
      sx={{
        borderBottom: row.getIsExpanded() ? "1px solid" : "none",
        borderColor: "grey.200",
        filter: row.getIsExpanded() ? "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))" : "",
        minWidth: cell && cell.column.columnDef.minSize,
        width: cell && cell.column.getSize(),
      }}
      align={cell && cell.column.columnDef.meta?.align}
      alt={rowIndex % 2 === 0}
    >
      {children}
    </Box>
  </>
);
