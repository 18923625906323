import { Outlet, Params, RouteObject, redirect } from "react-router-dom";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import Leaderboards from "./components/leaderboard/Leaderboards";
import { StoreManagement } from "./components/stores/settings/StoreManagement";
import { PermissionsManager } from "./components/admin/PermissionsManager";
import SharedThrowSetRoute from "./components/routes/SharedThrowSetRoute";
import { USER_ROLES } from "./components/user/consts";
import StoreLocatorRoute from "./components/routes/StoreLocatorRoute";
import { APIProvider } from "@vis.gl/react-google-maps";
import InspectRoute from "./components/routes/InspectRoute";
import { currentEvent } from "./dashboard/liveEvents";
import SharedThrowRoute from "./components/routes/SharedThrowRoute";
import ThrowRoute from "./components/routes/ThrowRoute";
import ThrowsRoute from "./components/routes/ThrowsRoute";
import ThrowSetRoute from "./components/routes/ThrowSetRoute";
import ThrowSetsRoute from "./components/routes/ThrowSetsRoute";
import LiveLeaderboardRoute from "./components/routes/live/LiveLeaderboardRoute";
import LiveDashboardRoute from "./components/routes/live/LiveDashboardRoute";
import { DynamicAnalyzeDashboard } from "./components/analysis/AnalyzeDashboard";
import DiscsLayout from "./components/layout/DiscLayout";
import CreateDiscRoute from "./components/routes/discs/CreateDiscRoute";
import EditDiscRoute from "./components/routes/discs/EditDiscRoute";
import { UserSettings } from "./components/user/UserSettings";

import { MDXProvider } from "@mdx-js/react";
import TermsOfService from "./components/TermsOfService.mdx";
import PrivacyPolicy from "./components/PrivacyPolicy.mdx";
import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { DevicesDashboard } from "./build/DevicesDashboard";
import SimDashboard from "./dashboard/SimDashboard";
import SimulatorRoute from "./components/routes/SimulatorRoute";
import { t } from "i18next";
import DiscCollectionRoute from "./components/routes/discs/DiscCollectionRoute";
import { DiscClass } from "./model/discs";
import StockShotEntryRoute from "./components/routes/stock-shots/StockShotEntryRoute";
import CreateStockShotRoute from "./components/routes/stock-shots/CreateStockShotRoute";
import StockShotIndexRoute from "./components/routes/stock-shots/StockShotIndexRoute";
import TestDriveRoute from "./components/routes/TestDrive";
import { TestDriveEntry } from "./components/test-drive/TestDriveEntry";
import { TestDriveThrow } from "./components/test-drive/TestDriveThrow";
import TestDriveReview from "./components/test-drive/TestDriveReview";

export const throwRoutes: RouteObject[] = [
  {
    path: "throws",
    element: <Outlet />,
    children: [
      {
        index: true,
        handle: { title: "Throws" },
        element: <ThrowsRoute />,
      },
      {
        path: ":throwId",
        element: <ThrowRoute />,
      },
      {
        path: "sets",
        element: <Outlet />,
        children: [
          {
            handle: { title: "Throw Sets" },
            index: true,
            element: <ThrowSetsRoute />,
          },
          {
            path: ":throwSetId",
            element: <ThrowSetRoute />,
          },
          {
            path: "*",
            element: <DynamicAnalyzeDashboard />,
            loader: ({ request }) => {
              if (!request.url.includes("live")) {
                return redirect(`/live/${currentEvent?.event}/throws`, 302);
              }
              return null;
            },
          },
        ],
      },
    ],
  },
];
export const shareRoutes: RouteObject[] = [
  {
    path: "s/throws/:userId/:throwId",
    handle: { title: "Shared Throw" },
    element: <SharedThrowRoute />,
  },
  {
    path: "s/throws/sets/:userId/:throwSetId",
    handle: { title: "Shared Throw Set" },
    element: <SharedThrowSetRoute />,
  },
];
export const redirectRoutes: RouteObject[] = [
  // Redirect legacy URLs
  {
    path: "/index.html",
    // Handle shared throws redirect
    loader: ({ request }) => {
      const searchParams = new URL(request.url).searchParams;
      const { uid, throwId, id } = Object.fromEntries(searchParams);
      if (uid && (throwId || id)) {
        return redirect(`/s/throws/${uid}/${throwId || id}`, 302);
      }
      return redirect("/", 302);
    },
  },
  {
    path: "devices.html",
    loader: () => {
      return redirect("/devices", 302);
    },
  },
  {
    path: "simulate.html",
    loader: () => {
      return redirect("/simulate", 302);
    },
  },
  {
    path: "device.html",
    loader: () => {
      return redirect("/device", 302);
    },
  },
  {
    path: "table.html",
    loader: () => {
      return redirect("/throws", 302);
    },
  },
  {
    path: "sets.html",
    loader: () => {
      return redirect("/throws/sets", 302);
    },
  },
  {
    path: "latest.html",
    loader: ({ request }) => {
      const searchParams = new URL(request.url).searchParams;
      const throwId = searchParams.get("throwId");
      if (!throwId) {
        return redirect("/throws", 302);
      }
      return redirect(`/throws/${throwId}`, 302);
    },
  },
  {
    path: "kiosk.html",
    loader: ({ request }) => {
      const searchParams = new URL(request.url).searchParams;
      const id = searchParams.get("id");
      if (!id) {
        return redirect("/throws/sets", 302);
      }
      return redirect(`/throws/sets/${id}`, 302);
    },
  },
];
export const liveRoutes: RouteObject[] = [
  {
    path: "live",
    element: <Outlet />,
    children: [
      {
        index: true,
        loader: () => {
          return redirect(`/live/${currentEvent?.event}`, 302);
        },
      },
      {
        path: "leaderboards",
        handle: { title: "Live Leaderboard" },
        element: <LiveLeaderboardRoute />,
      },
      {
        path: ":liveEvent",
        element: <Outlet />,
        children: [
          ...throwRoutes,
          {
            index: true,
            handle: { title: "Events" },
            element: <LiveDashboardRoute />,
          },
        ],
      },
    ],
  },
];
export const adminRoutes: RouteObject[] = [
  {
    path: "admin",
    element: <ProtectedRoute requiredRoles={[USER_ROLES.MANAGER]} />,
    children: [
      {
        path: "permissions",
        handle: { title: "User Management" },
        element: (
          <ProtectedRoute requiredRoles={[USER_ROLES.MANAGER]}>
            <PermissionsManager />
          </ProtectedRoute>
        ),
      },
      {
        path: "inspect",
        handle: { title: "Inspect User" },
        element: (
          <ProtectedRoute requiredRoles={[USER_ROLES.MANAGER]}>
            <InspectRoute />
          </ProtectedRoute>
        ),
        children: [
          {
            path: ":userId",
            element: <Outlet />,
            children: throwRoutes,
          },
        ],
      },
    ],
  },
];
export const storeRoutes: RouteObject[] = [
  {
    path: "stores",
    handle: { title: "Stores" },
    element: (
      <ProtectedRoute>
        <APIProvider apiKey={"AIzaSyCutDl2Mu7LYZpfFk_OoefhfE7yKUpYlo0"}>
          <StoreLocatorRoute />
        </APIProvider>
      </ProtectedRoute>
    ),
  },
  {
    path: "test-drive",
    handle: { title: "Test Drive" },
    element: (
      <ProtectedRoute requiredRoles={[USER_ROLES.STORE]}>
        <TestDriveRoute />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <TestDriveEntry />,
        handle: { currentStep: 0 },
      },
      {
        path: "throw",
        element: <TestDriveThrow />,
        handle: { currentStep: 1 },
      },
      {
        path: "review/:analysisId",
        element: <TestDriveReview />,
        handle: { currentStep: 2 },
      },
    ],
  },
  {
    path: "leaderboards",
    handle: { title: "Leaderboards" },
    element: (
      <ProtectedRoute requiredRoles={[USER_ROLES.PREMIUM_STORE]}>
        <Leaderboards />
      </ProtectedRoute>
    ),
  },
  {
    // Shareable link for store leaderboards
    path: "leaderboards/:leaderboardId",
    handle: { title: "Leaderboard" },
    element: <Leaderboards />,
  },
  {
    path: "store-management",
    handle: { title: "Store Management" },
    element: (
      <ProtectedRoute requiredRoles={[USER_ROLES.STORE]}>
        <StoreManagement />
      </ProtectedRoute>
    ),
  },
];
export const discRoutes: RouteObject[] = [
  {
    path: "/discs",
    element: (
      <ProtectedRoute
        requiredRoles={[USER_ROLES.USER]}
        requiredFeatureFlags={["feature_collection"]}
      >
        <DiscsLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: ":discClass",
        loader: ({ params }) => {
          const { discClass } = params;
          if (!Object.values(DiscClass).includes(discClass as DiscClass)) {
            return redirect("/discs", 302);
          }
          return null;
        },
        handle: {
          title: (params: Params) => `${t(`discClass.proper.${params.discClass}_other`)}`,
        },
        element: <DiscCollectionRoute />,
      },
      {
        index: true,
        handle: {
          title: t("discCollection"),
        },
        element: <DiscCollectionRoute />,
      },
      {
        path: "new/:discClass?",
        handle: {
          title: (params: Params) =>
            params.discClass
              ? t("createItem", { item: t(`discClass.proper.${params.discClass}_one`) })
              : t("createItem", { item: t("disc_capital_one") }),
        },
        element: <CreateDiscRoute />,
      },
      {
        path: "edit/:discId?",
        handle: {
          title: t("editDisc"),
        },
        element: <EditDiscRoute />,
      },
    ],
  },
];
export const settingsRoutes: RouteObject[] = [
  {
    path: "settings",
    handle: { title: "Settings" },
    element: (
      <ProtectedRoute requiredRoles={[USER_ROLES.USER]}>
        <UserSettings />
      </ProtectedRoute>
    ),
  },
];
export const deviceRoutes: RouteObject[] = [
  {
    path: "device",
    element: null,
    children: [{ path: ":deviceUid", element: null }],
  },
  {
    path: "devices",
    handle: { title: t("devices") },
    element: (
      <ProtectedRoute requiredRoles={[USER_ROLES.MANUFACTURING]}>
        <DevicesDashboard />
      </ProtectedRoute>
    ),
  },
];
export const simulatorRoutes: RouteObject[] = [
  {
    path: "simulate",
    handle: { title: t("simulator_capital_one") },
    element: <SimDashboard />,
  },
  {
    path: "simulator",
    handle: { title: t("simulator_capital_one") },
    element: <SimulatorRoute />,
  },
];
export const stockShotRoutes: RouteObject[] = [
  {
    path: "stock-shots",
    handle: { title: t("stockShot") },
    element: (
      <ProtectedRoute requiredRoles={[USER_ROLES.USER]} requiredFeatureFlags={["lab"]}>
        <StockShotEntryRoute />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <StockShotIndexRoute />,
      },
      {
        path: "create",
        element: <CreateStockShotRoute />,
      },
    ],
  },
];

const createMDXComponents = () => ({
  ...["h1", "h2", "h3", "h4", "h5", "h6"].reduce(
    (components, tag) => ({
      [tag as Variant]: (props: { children: React.ReactNode }) => (
        <Typography variant={tag as Variant}>{props.children}</Typography>
      ),
      ...components,
    }),
    {},
  ),
  p: (props: React.HTMLAttributes<HTMLParagraphElement>) => (
    <Typography variant={"body1"} sx={{ lineHeight: "1.7rem" }}>
      {props.children}
    </Typography>
  ),
  ul: (props: React.HTMLAttributes<HTMLUListElement>) => (
    <Typography component="ul" variant={"body2"} sx={{ lineHeight: "2rem" }}>
      {props.children}
    </Typography>
  ),
  ol: (props: React.HTMLAttributes<HTMLOListElement>) => (
    <Typography component="ol" variant={"body2"} sx={{ lineHeight: "2rem" }}>
      {props.children}
    </Typography>
  ),
  u: (props: React.HTMLAttributes<HTMLSpanElement>) => (
    <Typography component="span" variant="body1" sx={{ textDecoration: "underline" }}>
      {props.children}
    </Typography>
  ),
});

export const legalRoutes: RouteObject[] = [
  {
    path: "privacy-policy",
    handle: { title: "Privacy Policy" },
    element: (
      <MDXProvider components={createMDXComponents()}>
        <PrivacyPolicy />
      </MDXProvider>
    ),
  },
  {
    path: "/terms",
    handle: { title: "Terms of Service" },
    element: (
      <MDXProvider components={createMDXComponents()}>
        <TermsOfService />
      </MDXProvider>
    ),
  },
];
