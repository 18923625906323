import { ThrowTable } from "../../analysis/ThrowTable";
import { useUser } from "../../hooks/useUser";
import useLive from "../../hooks/useLive";
import { LIVE_USER_ID, liveEvents } from "../../dashboard/liveEvents";
import { DateRange } from "rsuite/esm/DateRangePicker";
import { addMonths } from "date-fns/esm";
import { useMemo } from "react";

export default function ThrowsRoute() {
  const [{ trueUserId, externalUserId }, userLoading] = useUser();
  const { isLiveRoute, liveEvent } = useLive();
  const event = liveEvents.find((event) => event.event === liveEvent);

  const defaultLimit = useMemo(
    () => (isLiveRoute && event ? event?.queryLimit : 250),
    [isLiveRoute, event],
  );
  const defaultRange: DateRange = useMemo(
    () => (isLiveRoute && event ? event?.dates : [addMonths(new Date(), -6), new Date()]),
    [isLiveRoute, event],
  );

  if (externalUserId) {
    return (
      // <ErrorBoundary eventName="external_user_throws_error">
      userLoading ? null : (
        <ThrowTable userId={externalUserId} dateRange={defaultRange} queryLimit={defaultLimit} />
      )
      // </ErrorBoundary>
    );
  }

  if (isLiveRoute) {
    return (
      // <ErrorBoundary eventName="live_user_throws_error">
      userLoading ? null : (
        <ThrowTable
          key={defaultRange.join("-")}
          userId={LIVE_USER_ID}
          dateRange={defaultRange}
          queryLimit={defaultLimit}
        />
      )
      // </ErrorBoundary>
    );
  }

  if (trueUserId) {
    return (
      // <ErrorBoundary eventName="user_throws_error">
      userLoading ? null : (
        <ThrowTable userId={trueUserId} dateRange={defaultRange} queryLimit={defaultLimit} />
      )
      // </ErrorBoundary>
    );
  }

  return (
    // <ErrorBoundary eventName="default_user_throws_error">
    userLoading ? null : (
      <ThrowTable userId={"unknown"} dateRange={defaultRange} queryLimit={defaultLimit} />
    )
    // </ErrorBoundary>
  );
}
