import hasClass from "./hasClass.js";
/**
 * Adds specific class to a given element
 *
 * @param target The element to add class to
 * @param className The class to be added
 *
 * @returns The target element
 */

export default function addClass(target, className) {
  if (className) {
    if (target.classList) {
      target.classList.add(className);
    } else if (!hasClass(target, className)) {
      target.className = target.className + " " + className;
    }
  }

  return target;
}