import Unauthenticated from "../layout/Unauthenticated";
import Unauthorized from "../layout/Unauthorized";
import { usePermissions } from "../../hooks/usePermissions/usePermissions";
import { UserRole } from "../user/types";
import { Outlet } from "react-router-dom";
import { FeatureFlag } from "../../featureFlags";
import { USER_ROLES } from "../user/consts";

export type ProtectedRouteProps = {
  requiredRoles?: UserRole[];
  requiredFeatureFlags?: FeatureFlag[];
  children?: React.ReactNode;
};

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { requiredRoles, requiredFeatureFlags } = props;

  const {
    user,
    isLoading: permissionsLoading,
    isAuthorized,
  } = usePermissions({ requiredRoles, requiredFeatureFlags });

  const isLoading = permissionsLoading;

  if (isLoading) {
    return null;
  }

  if (requiredRoles?.includes(USER_ROLES.USER) && !user) {
    return <Unauthenticated />;
  }

  if (requiredRoles) {
    if (!isAuthorized) {
      return <Unauthorized />;
    }
  }

  return props.children ? props.children : <Outlet />;
}
