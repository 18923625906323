import { Box, BoxProps, alpha } from "@mui/material";
import PlaceSharpIcon from "@mui/icons-material/PlaceSharp";

export function TechDiscHQPin(props: BoxProps & { selected?: boolean }) {
  return (
    <Box
      {...props}
      sx={{
        position: "relative",
        outline: (theme) =>
          props.selected ? `2px solid ${alpha(theme.palette.primary.main, 0.9)}` : "none",
        outlineOffset: "-2px",
        ...props.sx,
      }}
    >
      <PlaceSharpIcon
        sx={{
          color: "#1C2127",
          width: "100%",
          height: "100%",
        }}
      />
      <Box
        sx={{
          width: "16px",
          height: "16px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#1C2127",
          borderRadius: "100%",
        }}
      />
      <Box
        component="img"
        src="https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDiscLight_noWords.svg"
        width="24px"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -65%)",
        }}
      />
    </Box>
  );
}
