import ownerDocument from "./ownerDocument.js";
import getWindow from "./getWindow.js";
import contains from "./contains.js";

/**
 * Get the offset of a DOM element
 * @param node The DOM element
 * @returns The offset of the DOM element
 */
export default function getOffset(node) {
  var doc = ownerDocument(node);
  var win = getWindow(doc);
  var docElem = doc && doc.documentElement;
  var box = {
    top: 0,
    left: 0,
    height: 0,
    width: 0
  };

  if (!doc) {
    return null;
  } // Make sure it's not a disconnected DOM node


  if (!contains(docElem, node)) {
    return box;
  }

  if ((node === null || node === void 0 ? void 0 : node.getBoundingClientRect) !== undefined) {
    box = node.getBoundingClientRect();
  }

  if ((box.width || box.height) && docElem && win) {
    box = {
      top: box.top + (win.pageYOffset || docElem.scrollTop) - (docElem.clientTop || 0),
      left: box.left + (win.pageXOffset || docElem.scrollLeft) - (docElem.clientLeft || 0),
      width: (box.width === null ? node.offsetWidth : box.width) || 0,
      height: (box.height === null ? node.offsetHeight : box.height) || 0
    };
  }

  return box;
}