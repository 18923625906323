import { useState, useEffect } from "react";
import { Chip, Stack, IconButton, TextField } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { setDocWithLogging } from "../summaryUtils";
import { arrayRemove, arrayUnion } from "firebase/firestore";
import TagManager from "../components/TagManager";
import useNotify from "../hooks/useNotify";
import { CoreStats } from "../model/CoreStats";
import { getThrowSummary } from "../firebase/docs";

export enum PrimaryThrowType {
  RHBH = "RHBH",
  RHFH = "RHFH",
  RHBHG = "RHBHG",
  RHFHG = "RHFHG",
  RHTHU = "RHTHU",
  RHTOM = "RHTOM",
}

export enum BasicThrowType {
  Backhand = "Backhand",
  Forehand = "Forehand",
  Grenade = "Grenade",
  Overhand = "Overhand",
}

export type PrimaryThrowStrings = keyof typeof PrimaryThrowType | keyof typeof BasicThrowType;

export const SECONDARY_TYPES: string[] = ["Roller", "Anhyzer", "Flat", "Hyzer", "Spike"];

export default function Tags(props: {
  coreStats: CoreStats;
  userId: string;
  isDashboard?: boolean;
}) {
  const { userId, coreStats, isDashboard } = props;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [lockedTags, setLockedTags] = useState<string[]>([]);
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [showCustomField, setShowCustomField] = useState<boolean>(false);
  const [customTag, setCustomTag] = useState<string>("");
  const notify = useNotify();

  useEffect(() => {
    setLockedTags(TagManager.getLockedTags(userId));
    setSuggestedTags(TagManager.getSuggestedTags(userId));
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setLockedTags(lockedTags);
      TagManager.setLockedTags(userId, lockedTags);
    }
  }, [lockedTags]);

  useEffect(() => {
    clearFilteredTags(); // whenever the props tags are changed do this
  }, [coreStats?.tags]);

  function clearFilteredTags(): string[] {
    let suggested = TagManager.getSuggestedTags(userId);

    // filter out used tags
    suggested = suggested.filter((el: string) => !coreStats.tags?.includes(el));
    setSuggestedTags(suggested);

    return suggested;
  }

  function showToggleLockedButton(): boolean {
    let show = false;
    if (coreStats?.tags && coreStats.tags.length > 0) {
      // show = !(getThrowIdQueryParam() || window?.location?.pathname === "/throws"); // ensure not the table view or single throw view
      show = !!isDashboard;
    }
    return show;
  }

  function handleAddition(tag: string) {
    // TODO: disallow all custom tags if user is on the free tier
    if (tag.length > 40) {
      notify("warning", "Tag is too long: " + tag);
      return;
    }

    try {
      if (hasTag(coreStats?.tags, tag)) {
        notify("warning", "Tag is already on throw: " + tag);
        return;
      }
    } catch (e) {
      console.warn("caught in handleAddition", e);
    }
    // save to store
    TagManager.addSuggestedTag(userId, tag);
    const doc = getThrowSummary(userId, coreStats.id);
    setDocWithLogging(
      doc,
      {
        tags: arrayUnion(tag),
      },
      { merge: true },
    );
  }

  function isLockedTag(t: string, toDelete?: boolean) {
    if (!showToggleLockedButton()) {
      // before adjusting props per view this will tell us there should be no locking tags on this page
      return false;
    }
    for (const lt of lockedTags) {
      if (t == lt) {
        if (toDelete) {
          // extra passed bool to handle this right away
          try {
            const filtered = lockedTags?.filter((el) => el != t);
            setLockedTags(filtered);
          } catch (e) {
            // just to be safe
          }
        }
        return true;
      }
    }
    return false;
  }

  function hasTag(array?: string[], tag?: string) {
    if (!array || !tag) {
      return false;
    }
    for (const el of array) {
      if (el.toLowerCase() == tag.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  //   {showToggleLockedButton() ? (
  //     <IconButton
  //       // make even smaller
  //       sx={{ padding: 0, marginTop: -1, marginLeft: "0.4rem" }}
  //       color={"primary"}
  //       size={"small"}
  //       onClick={() => {
  //         if (!isTagsLocked()) {
  //           // lock
  //           setLockedTags(coreStats.tags || []);
  //         } else {
  //           // unlock
  //           setLockedTags([]);
  //         }
  //       }}
  //     >
  //       {isTagsLocked() ? <Icon icon="lock" size={20} /> : <Icon icon="unlock" size={20} />}
  //     </IconButton>
  //   ) : null}
  return (
    <Stack spacing={1}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
        {coreStats.tags?.map((t: string, i: number) => (
          <Chip
            sx={{ height: 40 }}
            key={i.toString()}
            label={t}
            size="medium"
            color={"primary"}
            variant={isLockedTag(t) ? "filled" : "outlined"}
            onClick={(e) => {
              if (showToggleLockedButton()) {
                // checking that we should be allowed to toggle here
                if (!isLockedTag(t)) {
                  setLockedTags([...lockedTags, t]);
                } else {
                  const filtered = lockedTags?.filter((el) => el != t);
                  setLockedTags(filtered);
                }
              }
            }}
            onDelete={(o) => {
              // runs for tags on any throw view, above is throw set view
              const doc = getThrowSummary(userId, coreStats.id);
              setDocWithLogging(
                doc,
                {
                  tags: arrayRemove(t),
                },
                { merge: true },
              );
              isLockedTag(t, true);
            }}
          />
        ))}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
        <IconButton
          sx={{ mt: -0.25 }}
          color={showCustomField ? "default" : "primary"} // could change to danger but i think it's not
          onClick={(o) => {
            if (showCustomField) {
              // in case was filtered, reset to backup
              setCustomTag("");
              clearFilteredTags();
            }
            setShowCustomField(!showCustomField);
          }}
        >
          {showCustomField ? (
            <ZoomOutIcon sx={{ fontSize: "28px" }} />
          ) : (
            <ZoomInIcon sx={{ fontSize: "28px" }} />
          )}
        </IconButton>

        {showCustomField ? (
          <TextField
            placeholder="Type tag -> Enter"
            variant="outlined"
            autoFocus={true}
            size="small"
            sx={{
              "&  .MuiFormLabel-root": {
                fontSize: "0.8rem",
              },
              height: 20,
              "::placeholder": {
                fontSize: "0.8rem",
              },
              width: 180,
            }}
            value={customTag}
            color="primary"
            // use focus lost to store
            onBlur={(e) => {
              const newVal = e.target.value;
              if (!newVal) {
                return;
              }
              setCustomTag(newVal);
            }}
            onKeyDown={(e) => {
              clearFilteredTags(); // looks to be the best play to allow all search items in all conditions, versus below
              // if (e.key == "Backspace") {
              //   clearFilteredTags();
              // }
              if (e.key == "Enter") {
                if (customTag && customTag.length > 0) {
                  handleAddition(customTag);
                }
                setCustomTag("");
                clearFilteredTags();
              }
            }}
            onChange={(e) => {
              // filter suggested tags
              if (!e.target.value?.length) {
                clearFilteredTags();
              }
              setCustomTag(e.target.value);
              setSuggestedTags(
                suggestedTags.filter((st) => {
                  const condition: boolean = st
                    ?.toLowerCase()
                    .startsWith(e.target.value.toLowerCase());
                  return condition;
                }),
              );
            }}
          />
        ) : null}
        {/* might do a smart slice on length of actual text in all tags combined so that it nicely fits the window, using normal one word tags, 10 is great, big full names, 10 not so great */}
        {suggestedTags?.slice(0, 10).map((t: string, i: number) =>
          !coreStats?.tags?.includes(t) ? (
            <Chip
              sx={{ height: 40 }}
              key={i.toString()}
              label={t}
              color="default"
              variant="outlined"
              onClick={(o) => {
                handleAddition(t);
              }}
              onDelete={(o) => {
                setSuggestedTags(TagManager.deleteSuggestedTag(userId, t, suggestedTags));
              }}
            />
          ) : null,
        )}
      </div>
    </Stack>
  );
}
