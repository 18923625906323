import { getAnalytics, logEvent } from "firebase/analytics";
import React, { ReactNode } from "react";
import { firebaseApp } from "../firebaseConfig";
import { ThrowSummaryAndId } from "../latestDashboard";
import Flight3D from "../dashboard/Flight3D";
import { UnityComponent } from "./flight/UnityComponent";
import { ThrowSummary } from "../model/throwSummary";

class ErrorBoundary extends React.Component<
  { flights?: ThrowSummaryAndId[]; children: ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logEvent(getAnalytics(firebaseApp), "simulator_error", {
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.flights) {
        return <Flight3D flights={this.props.flights} />;
      }
      //   return <Simulate3D flightParams={this.props.flights} isFlightPathMode={true} />;
    }

    return this.props.children;
  }
}

// single is for one throw, multiple is for many throws
export type SimulatorMode = "single" | "multiple";

interface SimulatorProps {
  mode: SimulatorMode;
  flights?: ThrowSummary[];
  fallback?: JSX.Element;
  userId: string;
}

function Simulator(props: SimulatorProps) {
  const { flights, userId, mode = "single" } = props;

  return flights && flights?.length > 0 ? (
    <ErrorBoundary flights={flights}>
      <UnityComponent mode={mode} recentThrows={flights} summary={flights[0]} userId={userId} />
    </ErrorBoundary>
  ) : (
    <ErrorBoundary>
      <UnityComponent mode={mode} userId={userId} />
    </ErrorBoundary>
  );
}

export default Simulator;
