import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";
import { getLatLng } from "../utils/map";
import { StoreMetadata } from "../firebase/converters/leaderboardStoreMetadata";
import { TD_HQ_USER_ID } from "../components/consts";
import { PremiumStorePin } from "../components/icons/PremiumStorePin";
import { StorePin } from "../components/icons/StorePin";

export function StoreMarker(props: {
  key?: string;
  store?: StoreMetadata;
  selectedStore?: StoreMetadata;
  onSelect?: (store?: StoreMetadata) => void;
}) {
  const { key, store, selectedStore, onSelect } = props;
  const map = useMap();
  if (store?.hideMapLocation) {
    return null;
  }
  const isTechDiscHQ = store?.id === TD_HQ_USER_ID;
  const position = getLatLng(store);
  map?.addListener("click", (e) => {
    // clear selection
    onSelect?.();
  });

  if (map && position) {
    return (
      <AdvancedMarker
        key={key}
        position={position}
        title={store?.name}
        onClick={() => {
          onSelect?.(store);
        }}
        zIndex={isTechDiscHQ ? 1 : store?.isPremium ? 1 : 0}
      >
        {store?.isPremium ? (
          <PremiumStorePin
            selected={selectedStore?.id === store?.id}
            sx={{ position: "relative", width: "48px", height: "48px" }}
          />
        ) : (
          <StorePin
            selected={selectedStore?.id === store?.id}
            sx={{ position: "relative", width: "48px", height: "48px" }}
          />
        )}
      </AdvancedMarker>
    );
  }

  return null;
}
