import { useMemo, useState } from "react";
import StoreMap from "../../dashboard/StoreMap";
import { StoreMarker } from "../../dashboard/StoreMarker";
import { Stack, Box, Container, Button, darken } from "@mui/material";
import { limit, query, QueryConstraint, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { getLeaderboardStoreMetadataCollection } from "../../firebase/collections";
import { StoreInfoCard } from "../stores/StoreInfoCard";
import {
  StoreMetadata,
  leaderboardStoreMetadataConverter,
} from "../../firebase/converters/leaderboardStoreMetadata";
import StoreMapLegend from "../stores/StoreMapLegend";
import { Link } from "react-router-dom";
import { ErrorBoundary } from "../ErrorBoundary";

const StoreLocatorRoute = () => {
  const [selectedStore, setSelectedStore] = useState<StoreMetadata>();

  const storesWithValidAddresses = useMemo(() => {
    const filters: QueryConstraint[] = [];
    filters.push(where("addressValidity.result.geocode", "!=", null));
    filters.push(limit(200));
    return query(getLeaderboardStoreMetadataCollection(), ...filters).withConverter(
      leaderboardStoreMetadataConverter,
    );
  }, []);

  const [stores] = useCollectionData(storesWithValidAddresses);

  return (
    // <ErrorBoundary eventName="store_map_error">
    <Stack>
      <Stack gap={4} direction="row" width="100%" height="calc(100dvh - 48px)" position="relative">
        <StoreMap>
          <StoreMapLegend
            sx={{
              position: "absolute",
              top: { md: 12 },
              bottom: { mobile: 12 },
              right: 12,
            }}
          />
          {selectedStore && (
            <Box sx={{ position: "absolute", top: 20, left: 20 }}>
              <StoreInfoCard store={selectedStore} onClose={() => setSelectedStore(undefined)} />
            </Box>
          )}
          <Box sx={{ position: "absolute", bottom: 12, left: 12 }}>
            <Box
              component="img"
              src="https://storage.googleapis.com/techdisc-cdn/logo_assets/TechDiscLight_onlyWords.svg"
              sx={{
                maxWidth: { md: "256px", mobile: "128px" },
                filter: "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
              }}
            />
          </Box>
          {stores &&
            stores?.map((store) => (
              <StoreMarker
                key={store.id}
                store={store}
                selectedStore={selectedStore}
                onSelect={setSelectedStore}
              />
            ))}
        </StoreMap>
      </Stack>
      <Stack component={Container} sx={{ my: 8 }} gap={1}>
        <Box sx={{ fontSize: "32px", fontWeight: 500, color: "secondary.main" }}>
          Get On the Map!
        </Box>
        <Box sx={{ fontSize: "16px", fontWeight: 300, color: "grey.600" }}>
          Add Your Shop to Our TechDisc Locator
        </Box>
        <Box
          component={Link}
          to="mailto:help@techdisc.com?subject=Store%20Map%20Request"
          sx={{
            px: 2,
            py: 1.5,
            color: "white",
            textDecoration: "none",
            "&:hover": {
              color: "white",
              textDecoration: "none",
              backgroundColor: (theme) => darken(theme.palette.primary.main, 0.2),
            },
            backgroundColor: "primary.main",
            borderRadius: 1,
            width: "fit-content",
            mt: 2,
          }}
        >
          Contact Us
        </Box>
      </Stack>
    </Stack>
    // </ErrorBoundary>
  );
};

export default StoreLocatorRoute;
