import { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { currentEvent, LIVE_USER_ID } from "../../dashboard/liveEvents";
import { orderBy, query, Timestamp, where } from "firebase/firestore";
import { getLeaderboardWeeklyCollection } from "../../firebase/collections";
import LeaderboardTable from "./LeaderboardTable";

export const LiveEventLeaderboardTable = () => {
  const startDate = Timestamp.fromMillis(currentEvent?.dates[0].getTime() ?? 0);
  const endDate = Timestamp.fromMillis(currentEvent?.dates[1].getTime() ?? 0);

  const leaderboardsQuery = useMemo(() => {
    const filters = [
      where("userId", "==", LIVE_USER_ID),
      where("throwTime", ">=", startDate),
      where("throwTime", "<=", endDate),
      orderBy("throwTime", "desc"),
    ];
    return query(getLeaderboardWeeklyCollection(), ...filters);
  }, [startDate, endDate]);

  const [data] = useCollectionData(leaderboardsQuery);
  return <LeaderboardTable data={data ?? []} />;
};
