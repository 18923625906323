import canUseDOM from "./canUseDOM.js";

var fallback = function fallback(context, node) {
  if (!node) return false;

  do {
    if (node === context) {
      return true;
    }
  } while (node.parentNode && (node = node.parentNode));

  return false;
};
/**
 * Checks if an element contains another given element.
 *
 * @param context The context element
 * @param node The element to check
 * @returns  `true` if the given element is contained, `false` otherwise
 */


var contains = function contains(context, node) {
  if (!node) return false;

  if (context.contains) {
    return context.contains(node);
  } else if (context.compareDocumentPosition) {
    return context === node || !!(context.compareDocumentPosition(node) & 16);
  }

  return fallback(context, node);
};

export default (function () {
  return canUseDOM ? contains : fallback;
})();