import useLive from "../../hooks/useLive";
import { isEventLive, isEventUpcoming } from "../../dashboard/liveEvents";
import { useUser } from "../../hooks/useUser";
import { TechDiscBanner } from "../../dashboard/ShopBanner";
import { LiveBanner } from "../banners/LiveBanner";

export default function UnauthenticatedBanner() {
  const { isLiveRoute } = useLive();
  const [{ user, userLoading }] = useUser();

  return isLiveRoute || isEventLive || isEventUpcoming ? (
    <LiveBanner />
  ) : !user && !userLoading ? (
    <TechDiscBanner />
  ) : null;
}
