import getWindow from "./getWindow.js";
import getOffset from "./getOffset.js";
/**
 * Get the width of a DOM element
 * @param node The DOM element
 * @param client Whether to get the client width
 * @returns The width of the DOM element
 */

export default function getWidth(node, client) {
  var win = getWindow(node);

  if (win) {
    return win.innerWidth;
  }

  if (client) {
    return node.clientWidth;
  }

  var offset = getOffset(node);
  return offset ? offset.width : 0;
}