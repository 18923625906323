import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Heavy, Medium, Strong } from "../Text";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { useForm, type SubmitHandler } from "react-hook-form";
import { LeftRightHandToggle } from "../../dashboard/Account";
import WavingHandIcon from "@mui/icons-material/SportsHandball";
import { useUser } from "../../hooks/useUser";
import { useState } from "react";
import { Handedness } from "../../model/UserSettings";
import { useSearchParams } from "react-router-dom";
import { useGlobal } from "../providers/GlobalProvider";
import { useNavigate } from "react-router-dom";
const schema = yup
  .object({
    email: yup.string().required("Email is required").email("Email must be a valid email address"),
    displayName: yup.string().optional(),
    totalThrows: yup.number().required().min(1).max(10),
  })
  .required();
type FormValues = yup.InferType<typeof schema>;

export const TestDriveEntry = () => {
  const [searchParams] = useSearchParams();
  const { isLiveAccount, featureFlags } = useGlobal();
  const [{ userSettings }] = useUser();
  const isLive = isLiveAccount && featureFlags.live_event;
  const [handedness, setHandedness] = useState<Handedness | undefined>(undefined);
  const { register, handleSubmit, formState } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { email, displayName, totalThrows } = data;
    navigate("throw", { state: { email, displayName, handedness, totalThrows } });
  };

  return (
    <Stack gap={4}>
      <Stack gap={0.5}>
        <Heavy size={36} letterSpacing={"-0.1rem"} color="secondary.dark">
          TEST DRIVE
        </Heavy>
        <Medium size={14} color="secondary.light">
          Fill out the information below to receive the results of your session!
        </Medium>
      </Stack>
      <Stack
        component="form"
        direction={{ mobile: "column", tablet: "row" }}
        sx={{
          gap: 4,
          width: "100%",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Paper variant="outlined" sx={{ p: 2, width: "100%" }}>
          <Stack direction={{ mobile: "column", tablet: "row" }} gap={4}>
            <Stack gap={1} sx={{ width: "100%" }}>
              <FormLabel component={Stack} direction="row" htmlFor="email">
                <Medium>Email Address</Medium>
                <Heavy>*</Heavy>
              </FormLabel>
              <TextField
                id="email"
                size="small"
                type="email"
                autoComplete="off"
                {...register("email")}
                error={Boolean(formState.errors.email)}
                helperText={formState.errors.email?.message}
              />
            </Stack>
            {isLive && (
              <Stack gap={1} sx={{ width: "100%" }}>
                <FormLabel component={Stack} direction="row" gap={1} htmlFor="displayName">
                  <Medium>Display Name</Medium>
                  <Text size={12}>
                    <em>(optional)</em>
                  </Text>
                </FormLabel>
                <TextField
                  id="displayName"
                  autoComplete="off"
                  size="small"
                  {...register("displayName")}
                />
                <FormHelperText>
                  Appears as{" "}
                  <Strong size={12} display="inline" color="grey.700">
                    Name
                  </Strong>{" "}
                  on the leaderboard.
                </FormHelperText>
              </Stack>
            )}
          </Stack>
        </Paper>
        <Paper component={Stack} variant="outlined" alignContent={"center"} gap={2} p={2}>
          <FormLabel htmlFor="handedness">
            <Stack direction="row" gap={1} id="handedness">
              <WavingHandIcon
                sx={{
                  width: "32px",
                  height: "32px",
                  color: "#2A81DE",
                }}
              />
              <LeftRightHandToggle
                handedness={handedness ?? userSettings?.handedness ?? Handedness.RIGHT}
                setHandedness={setHandedness}
                skipStorage={true}
              />
            </Stack>
          </FormLabel>
          <FormControl size="small" sx={{ minWidth: "96px" }}>
            <InputLabel id="throws">Throws</InputLabel>
            <Select
              labelId="throws"
              defaultValue={parseInt(searchParams.get("count") ?? (isLive ? "3" : "5"), 10)}
              inputProps={register("totalThrows")}
              label="Throws"
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
          <Button
            color="success"
            disabled={!formState.isValid || formState.isSubmitting}
            variant="primary"
            size="large"
            type="submit"
          >
            Start
          </Button>
        </Paper>
      </Stack>
    </Stack>
  );
};
