import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter, redirect } from "react-router-dom";
import Layout from "./components/layout/Layout";
import NotFound from "./components/layout/NotFound";
import { setupFirebase } from "./firebaseConfig";
import AppProviders from "./components/providers/Providers";
import DashboardRoute from "./components/routes/DashboardRoute";
import {
  shareRoutes,
  throwRoutes,
  redirectRoutes,
  adminRoutes,
  storeRoutes,
  liveRoutes,
  discRoutes,
  settingsRoutes,
  legalRoutes,
  deviceRoutes,
  simulatorRoutes,
  stockShotRoutes,
} from "./routes";

setupFirebase();

const App = () => {
  const router = createBrowserRouter(
    [
      {
        element: (
          <AppProviders>
            <Layout />
          </AppProviders>
        ),
        children: [
          ...shareRoutes,
          ...throwRoutes,
          ...redirectRoutes,
          ...adminRoutes,
          ...storeRoutes,
          ...liveRoutes,
          ...discRoutes,
          ...settingsRoutes,
          ...legalRoutes,
          ...deviceRoutes,
          ...simulatorRoutes,
          ...stockShotRoutes,
          { path: "*", element: <NotFound /> },
          {
            index: true,
            handle: { title: "Dashboard" },
            path: "/",
            // Handle shared throws redirect
            loader: ({ request }) => {
              const searchParams = new URL(request.url).searchParams;
              const { uid, throwId, id } = Object.fromEntries(searchParams);
              if (uid && (throwId || id)) {
                return redirect(`/s/throws/${uid}/${throwId || id}`, 302);
              }
              return null;
            },
            element: <DashboardRoute />,
          },
        ],
      },
    ],
    {
      future: {
        // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
        v7_normalizeFormMethod: true,
      },
    },
  );

  return <RouterProvider router={router} fallbackElement={null} />;
};

const root = createRoot(document.getElementById("root"));
root.render(<App />);
