"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _CollaspedOutline["default"];
  }
});

var _CollaspedOutline = _interopRequireDefault(require("./lib/icons/CollaspedOutline"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
