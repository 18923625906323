"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _CollaspedOutline = _interopRequireDefault(require("@rsuite/icon-font/lib/action/CollaspedOutline"));

// Generated by script, don't edit it please.
var CollaspedOutline = (0, _createSvgIcon["default"])({
  as: _CollaspedOutline["default"],
  ariaLabel: 'collasped outline',
  category: 'action',
  displayName: 'CollaspedOutline'
});
var _default = CollaspedOutline;
exports["default"] = _default;
module.exports = exports.default;