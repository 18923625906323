import { ThrowSummary } from "../../model/throwSummary";
import { Box, Chip, Stack } from "@mui/material";
import { Handedness } from "../../model/UserSettings";
import { useGlobal } from "../providers/GlobalProvider";
import { getThrowStyle, isBackhandSpin } from "../../utils/throw";

interface ThrowChipProps {
  summary: ThrowSummary;
}
export function ThrowChip({ summary }: ThrowChipProps) {
  const clockwiseRotation = (summary?.rotPerSec ?? 1) > 0;
  const { userSettings } = useGlobal();
  const backhandSpin = isBackhandSpin(summary, summary?.handedness ?? userSettings?.handedness);
  const throwStyle = getThrowStyle(clockwiseRotation, summary?.handedness);
  const userThrowStyle = getThrowStyle(clockwiseRotation, userSettings?.handedness);
  const hasMatchingStyles = throwStyle === userThrowStyle;

  return (
    <Box>
      {throwStyle ? (
        <>
          <Chip
            label={throwStyle}
            size="small"
            sx={{
              "& .MuiChip-label": {},
              backgroundColor: hasMatchingStyles && backhandSpin ? "white" : "grey.200",
              border: "1px solid",
              borderColor: "grey.300",
              color: "grey.800",
            }}
          />
          {!hasMatchingStyles && (
            <Chip
              label={summary?.handedness === Handedness.LEFT ? "L" : "R"}
              size="small"
              sx={{
                "& .MuiChip-label": {},
                backgroundColor: "white",
                border: "1px solid",
                borderColor: "grey.300",
                color: "grey.800",
              }}
            />
          )}
        </>
      ) : (
        <Chip
          label={userThrowStyle}
          size="small"
          sx={{
            "& .MuiChip-label": {},
            backgroundColor: backhandSpin ? "white" : "grey.200",
            border: "1px solid",
            borderColor: "grey.300",
            color: "grey.800",
          }}
        />
      )}
    </Box>
  );
}
