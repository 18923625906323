import { Stack } from "@mui/material";
import { ThrowSetSparklines } from "../../dashboard/ThrowSetSparklines";
import CoreMetricsComponent from "../flight/CoreMetrics";
import { ThrowAnalysis } from "../../firebase/converters/analysisSet";
import { TableDataSummary } from "../../analysis/TableDataSummary";
import { TableRenderProps } from "../../analysis/ThrowTable";
import { useMemo, useState } from "react";
import { basicThrowType } from "../../analysis/ThrowTableInternal";
import { useGlobal } from "../providers/GlobalProvider";
import { Handedness } from "../../model/UserSettings";
import { generateTitle } from "./AnalysisSetTable";

export function ThrowAnalysisMetrics(
  props: Pick<TableRenderProps, "filters" | "limit" | "setFilters"> & {
    throwSets: ThrowAnalysis[];
  },
) {
  const { throwSets: rawThrowSets, filters, setFilters, limit } = props;
  const { userSettings } = useGlobal();
  const [latestThrowSet] = rawThrowSets;
  const [tags, setTags] = useState<string[]>([]);

  const throwSets = useMemo(
    () =>
      rawThrowSets.map((throwSet) => ({
        ...throwSet,
        primaryType:
          throwSet.avg?.primaryType ?? basicThrowType(throwSet, userSettings?.handedness),
        title:
          throwSet.title ?? generateTitle(throwSet, userSettings?.handedness ?? Handedness.RIGHT),
      })),
    [rawThrowSets, userSettings],
  );

  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: {
          md: "1fr 3fr",
          mobile: "1fr",
        },
        gap: { mobile: 1.5, md: 2 },
      }}
    >
      {throwSets && throwSets.length ? (
        throwSets.length > 1 ? (
          <>
            <TableDataSummary
              isThrowSets
              throwSets={throwSets}
              filters={filters}
              tags={tags}
              setFilters={setFilters}
              limit={limit}
            />
            <ThrowSetSparklines coreStats={throwSets} tags={tags} />
          </>
        ) : (
          <>
            <TableDataSummary
              isThrowSets
              throwSets={throwSets}
              filters={filters}
              tags={tags}
              setFilters={setFilters}
              limit={limit}
            />
            <CoreMetricsComponent summary={latestThrowSet} />
          </>
        )
      ) : null}
    </Stack>
  );
}
