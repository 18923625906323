import { Box, Button, Stack } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import * as THREE from "three";
import { DEFAULT_PRO_DISC_STATE, flightPathFromSummary } from "../../dashboard/SimDashboard";
import { FlightPathParams } from "../../dashboard/Simulate3D";
import { firebaseApp } from "../../firebaseConfig";
import { ThrowSummaryAndId } from "../../latestDashboard";
import { getTrueUserId } from "../../summaryUtils";
import { TrackPoint } from "../../trackUtils";
import { config, mobileConfig } from "../../simulator/utils";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Subject, distinctUntilChanged, tap } from "rxjs";
import { Fullscreen } from "@mui/icons-material";
import useTrackSubscription from "../../hooks/useTrackSubscription";
import { SimulatorMode } from "../Simulator";

declare global {
  interface Window {
    receiveFPSValues: (
      sceneName: string,
      averageFPS: number,
      percentile99: number,
      percentile1: number,
    ) => void;
    unityOnReady: () => void;
  }
}

function detectDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /ipad|android.*tablet|tablet.*android/i.test(userAgent);
  const isMobile = /iphone|android.*mobile|mobile.*android/i.test(userAgent);

  if (isTablet) {
    return "tablet";
  } else if (isMobile) {
    return "phone";
  } else {
    return "desktop";
  }
}

type Message = {
  track?: TrackPoint[];
  recentThrows: ThrowSummaryAndId[] | undefined;
  summary: ThrowSummaryAndId;
  flightPaths: FlightPathParams[] | undefined;
  timeZone: string;
};
// Create a subject to act as the message queue
const messageQueue$ = new Subject<Message>();

// Device detection utility
const getDeviceConfig = () => {
  const deviceType = detectDevice();
  if (deviceType === "phone" || deviceType === "tablet") {
    return mobileConfig;
  }
  return config;
};

interface UnityComponentProps {
  mode: SimulatorMode;
  userId: string;
  summary?: ThrowSummaryAndId;
  recentThrows?: ThrowSummaryAndId[];
  track?: TrackPoint[];
  flightPaths?: FlightPathParams[];
  buildName?: string;
}

export function UnityComponent(props: UnityComponentProps) {
  const { recentThrows, summary, flightPaths, userId, mode } = props;
  const { unityProvider, sendMessage, requestFullscreen, unload, initialisationError, isLoaded } =
    useUnityContext(getDeviceConfig());
  const [ready, setReady] = useState(false);

  const { backswing } = useTrackSubscription({
    userId,
    throwId: recentThrows?.[0]?.id,
    skip: !recentThrows?.[0]?.rotPerSec,
    type: "backswing",
  });

  useEffect(() => {
    window.unityOnReady = () => {
      console.log("Unity is ready");
      setReady(true);
    };

    window.receiveFPSValues = (
      sceneName: string,
      averageFPS: number,
      percentile99: number,
      percentile1: number,
    ) => {
      // console.info("Average FPS:", averageFPS); // TODObrett muting this

      logEvent(getAnalytics(firebaseApp), "unity_perf", {
        sceneName: sceneName,
        averageFPS: averageFPS,
        percentile99: percentile99,
        percentile1: percentile1,
        client: "web",
        userId: getTrueUserId(),
      });
    };
  }, []);

  useEffect(() => {
    return () => {
      try {
        const exec = async () => {
          if (isLoaded) {
            await unload?.();
            console.debug("Unity unloaded");
          }
        };
        exec();
      } catch (err) {
        console.error("Error unloading Unity:", err);
      }
    };
  }, [unload, isLoaded]);

  useEffect(() => {
    return () => {
      if (initialisationError) {
        console.error("Error initializing Unity:", initialisationError);
      }
    };
  }, [initialisationError]);

  useEffect(() => {
    if (ready) {
      const subscription = messageQueue$
        .pipe(
          distinctUntilChanged((previous, current) => {
            const prevTrack = previous.track;
            const currentTrack = current.track;
            const prevId = previous.summary?.id;
            const currentId = current.summary?.id;
            return (
              JSON.stringify(prevTrack) === JSON.stringify(currentTrack) &&
              JSON.stringify(prevId) === JSON.stringify(currentId)
            );
          }),
          tap((message) => {
            if (window.location.origin !== "https://techdisc.com") {
              console.groupCollapsed("Throw Sent to Simulator");
              console.debug(message);
              console.groupEnd();
            }
            sendMessage(
              "JavascriptHookManager",
              mode === "single" ? "ThrowDisc" : "KioskThrows",
              JSON.stringify(message),
            );
          }),
        )
        .subscribe({
          error: (err) => console.error("Error in combined message queue:", err),
        });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [ready, sendMessage, mode]);

  useEffect(() => {
    const adjust = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (ready && summary) {
      const message: Message = {
        track: backswing.map((tp) => ({
          time: tp?.time,
          position: tp?.position?.clone().applyQuaternion(adjust),
          q: adjust.clone().multiply(tp?.q?.clone().multiply(adjust)),
        })),
        recentThrows: recentThrows?.map((v) => v.originalSummary),
        flightPaths:
          flightPaths ??
          recentThrows?.map((v) => {
            return {
              ...flightPathFromSummary(v.originalSummary),
              flight_numbers: v.originalSummary.estimatedFlightNumbers ?? DEFAULT_PRO_DISC_STATE,
            };
          }),
        summary: summary.originalSummary,
        timeZone,
      };
      messageQueue$.next(message);
    }
  }, [backswing, ready, recentThrows, flightPaths, summary]);

  return (
    <Stack
      gap={1}
      sx={{
        outline: "0px",
        width: "100%",
        aspectRatio: 16 / 9,
      }}
    >
      <Stack
        sx={{
          display: { mobile: "flex", sm: "none" },
          px: { mobile: 1, md: 2 },
          alignItems: "center",
        }}
      >
        <Button
          size="small"
          onClick={() => requestFullscreen(true)}
          sx={{
            p: 0.5,
            background: "white",
            width: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.5,
          }}
          variant="outlined"
        >
          <Fullscreen fontSize="small" />
          <Stack sx={{ fontSize: "0.8rem" }}>FULLSCREEN</Stack>
        </Button>
      </Stack>
      <Box
        component={Unity}
        unityProvider={unityProvider}
        tabIndex={1}
        matchWebGLToCanvasSize
        devicePixelRatio={window.devicePixelRatio}
        sx={{
          outline: "0px",
          width: "100%",
          aspectRatio: 16 / 9,
          backgroundColor: "grey.50",
        }}
      />
    </Stack>
  );
}
