import { useState, useEffect } from "react";
import { StorageReference, getDownloadURL } from "firebase/storage";

export type StorageOptions = {
  skip: boolean;
  storageRef?: StorageReference;
};

/**
 * Custom hook to retrieve data from a Firebase storage reference.
 * @param {string} storageRefPath - The path to the storage reference.
 * @param {number} skipBytes - Number of bytes to skip from the start.
 * @returns {Object} - Object containing the data and loading state.
 */
export default function useStorage({ skip, storageRef }: StorageOptions) {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (skip || !storageRef) {
      return;
    }
    const fetchData = async () => {
      try {
        const url = await getDownloadURL(storageRef);
        setUrl(url);
        setLoading(false);
      } catch (err) {
        setError(err as Error);
        setLoading(false);
      }
    };

    fetchData();
  }, [storageRef, skip]);

  return { url, loading, error };
}
