import { Box, Paper, PaperProps, Stack, StackProps } from "@mui/material";
import { StorePin } from "../icons/StorePin";
import { PremiumStorePin } from "../icons/PremiumStorePin";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMap } from "@vis.gl/react-google-maps";

const items = [
  {
    id: "premium_store",
    label: (
      <Stack sx={{ color: "white" }} direction="row" alignItems="center">
        Store
        <Stack
          sx={{ color: "primary.main", fontWeight: 600, fontSize: "1.2rem" }}
          alignItems="baseline"
        >
          +
        </Stack>
      </Stack>
    ),
    Icon: PremiumStorePin,
  },
  {
    id: "store",
    label: <Stack sx={{ color: "white" }}>Store</Stack>,
    Icon: StorePin,
  },
];

function StoreMapLegend(props: PaperProps) {
  const [showLegendItemInfo, setShowLegendItemInfo] = useState<"store" | "premium_store" | null>(
    null,
  );
  const itemRef = useRef<HTMLDivElement>(null);
  const map = useMap();

  useEffect(() => {
    // Clear legend info on map click
    map?.addListener("click", () => {
      setShowLegendItemInfo(null);
    });
  }, [map]);

  const handleLegendItemClick = (source: "store" | "premium_store") => {
    setShowLegendItemInfo((prevSource) =>
      prevSource == null ? source : prevSource === source ? null : source,
    );
  };

  return (
    <Stack
      gap={2}
      sx={{
        ...props.sx,
      }}
      alignItems="flex-end"
      height="fit-content"
    >
      <LegendItemInfo
        sx={{ display: { mobile: "flex", md: "none" } }}
        showLegendItemInfo={showLegendItemInfo}
      />
      <Stack
        direction="row"
        sx={{
          p: 1,
          gap: 2,
          backgroundColor: "#1C2127",
          border: "1px solid",
          borderColor: "secondary.main",
          borderRadius: 1,
          width: "fit-content",
        }}
      >
        {items.map((item) => (
          <Stack
            id={item.id}
            key={item.id}
            direction="row"
            gap={0.5}
            alignItems="center"
            ref={itemRef}
            onClick={() => handleLegendItemClick(item.id as "store" | "premium_store")}
            sx={{ cursor: "pointer" }}
          >
            <item.Icon size="small" sx={{ width: 24, height: 24 }} />
            <Box sx={{ userSelect: "none" }}>{item.label}</Box>
          </Stack>
        ))}
      </Stack>
      <LegendItemInfo
        sx={{ display: { md: "flex", mobile: "none" } }}
        showLegendItemInfo={showLegendItemInfo}
      />
    </Stack>
  );
}

export default StoreMapLegend;

const LegendItemInfo = (
  props: { showLegendItemInfo: "store" | "premium_store" | null } & StackProps,
) => {
  const { showLegendItemInfo, ...rest } = props;
  return (
    <Stack {...rest}>
      {props.showLegendItemInfo === "store" && (
        <Stack
          sx={{
            p: 2,
            gap: 2,
            backgroundColor: "#1C2127",
            border: "1px solid",
            borderColor: "secondary.main",
            borderRadius: 1,
            color: "white",
            fontWeight: 200,
            maxWidth: "256px",
          }}
        >
          <Stack gap={1}>
            <Box sx={{ fontSize: "12px", fontWeight: 600 }}>TEST DRIVE</Box>
            <Box sx={{ fontSize: "14px", color: "grey.300" }}>
              Try the TechDisc, create an analysis set of your throwing session and get the results
              straight to your inbox!
            </Box>
          </Stack>
        </Stack>
      )}
      {props.showLegendItemInfo === "premium_store" && (
        <Stack
          sx={{
            p: 2,
            gap: 2,
            backgroundColor: "#1C2127",
            border: "1px solid",
            borderColor: "secondary.main",
            borderRadius: 1,
            color: "white",
            fontWeight: 200,
            maxWidth: "256px",
          }}
        >
          <Stack gap={1}>
            <Stack direction="row" alignItems="center">
              <Box sx={{ fontSize: "12px", fontWeight: 500 }}>STORE LEADERBOARD</Box>
              <Stack
                sx={{ color: "primary.main", fontWeight: 600, fontSize: "1.2rem" }}
                alignItems="center"
              >
                +
              </Stack>
            </Stack>
            <Box sx={{ fontSize: "14px", color: "grey.300" }}>
              Throw a session of <b>Test Drive</b> and compete against others in the area for a spot
              on the leaderboard! Can you get the top spot?
            </Box>
          </Stack>
          <Stack gap={1}>
            <Box sx={{ fontSize: "12px", fontWeight: 500 }}>TEST DRIVE</Box>
            <Box sx={{ fontSize: "14px", color: "grey.300" }}>
              Try the TechDisc, create an analysis set of your throwing session and get the results
              straight to your inbox!
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
