import {
  AppBar,
  Box,
  Button,
  Divider,
  Icon,
  List,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useLive from "../../hooks/useLive";
import { format, subDays } from "date-fns";
import {
  currentEvent,
  getEventTitle,
  isEventLive,
  liveEvents,
  pastEvents,
} from "../../dashboard/liveEvents";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useUser } from "../../hooks/useUser";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useState } from "react";

export const LiveBanner = () => {
  const { liveEvent } = useLive();
  const currentPath = useLocation().pathname;
  const [{ user, userLoading }] = useUser();
  const eventData = liveEvents.find((event) => event.event === liveEvent);

  if (!eventData) return null;

  const [startDate, endDate] = eventData?.dates ?? [];
  const isLiveRoute = currentPath.includes("/live");

  return (
    <AppBar position="static" sx={{ backgroundColor: "primary.dark" }}>
      <Toolbar
        sx={{
          flexDirection: { mobile: "column", tablet: "row" },
          justifyContent: { tablet: "space-between" },
          py: 1,
          gap: 0.5,
        }}
      >
        <Stack
          sx={{
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Stack direction="row" justifyContent={{ mobile: "center", tablet: "flex-start" }}>
            {isEventLive && currentEvent?.event === liveEvent ? (
              <Box
                sx={{
                  color: "primary.main",
                  fontSize: "20px",
                  letterSpacing: "-1px",
                  fontWeight: 600,
                }}
              >
                LIVE
              </Box>
            ) : (
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  letterSpacing: "0px",
                  fontWeight: 500,
                }}
              >
                {`${format(startDate, "LLLL do")} - ${format(subDays(endDate, 1), "LLLL do")}`}
              </Box>
            )}
          </Stack>
          <Stack
            component={isEventLive ? Link : "span"}
            to={isEventLive ? `/live/${eventData?.event}` : undefined}
            sx={{
              textAlign: { mobile: "center", tablet: "left" },
              justifyContent: { mobile: "center", tablet: "flex-start" },
              fontSize: "20px",
              width: "100%",
              color: "white",
              textWrap: "no-wrap",
              userSelect: "none",
            }}
          >
            {eventData?.label}
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          {isLiveRoute ? (
            <>
              {isEventLive && currentEvent?.event !== liveEvent && (
                <Button
                  component={Link}
                  variant="menu"
                  size="small"
                  sx={{
                    color: "white",
                    maxWidth: "100%",
                    textWrap: "no-wrap",
                    width: "fit-content",
                  }}
                  to={`/live/${currentEvent?.event}`}
                >
                  <Icon
                    component={EmojiEventsIcon}
                    fontSize="small"
                    sx={{ color: "accent.main" }}
                  />
                  {currentEvent?.label}
                </Button>
              )}
              {currentEvent?.event === liveEvent ? (
                <>
                  <EventMenu />
                  <PastEvents />
                </>
              ) : (
                <PastEvents />
              )}

              {user && !userLoading && (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ display: { mobile: "none", tablet: "flex" } }}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ borderColor: "secondary.light", mx: 0.5 }}
                  />
                  <Button component={Link} color="inherit" to={"/"}>
                    <KeyboardReturnIcon fontSize="small" /> Return
                  </Button>
                </Stack>
              )}
            </>
          ) : isEventLive ? (
            <EventMenu />
          ) : (
            <PastEvents />
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

const PastEvents = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const { liveEvent } = useLive();
  return (
    <>
      <Button
        variant="menu"
        size="small"
        id="past-events-button"
        aria-controls={open ? "past-events-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Stack sx={{ color: "white" }}>Past Events</Stack>
        <ArrowDropDownCircleOutlined />
      </Button>
      <Menu
        id="past-events-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "past-events-button",
        }}
      >
        {pastEvents
          .filter((event) => event.event !== liveEvent)
          .sort((a, b) => {
            const aStart =
              liveEvents.find((event) => event.event === a.event)?.dates[0] ?? new Date();
            const bStart =
              liveEvents.find((event) => event.event === b.event)?.dates[0] ?? new Date();
            return bStart.getTime() - aStart.getTime();
          })
          .map((event) => (
            <MenuItem
              key={event.event}
              onClick={() => {
                handleClose();
                navigate(`/live/${event.event}/throws`);
              }}
            >
              {getEventTitle(event.event)}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

const EventMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const { liveEvent } = useLive();

  return (
    <>
      <Button
        variant="menu"
        size="small"
        id="explore-events-button"
        aria-controls={open ? "explore-events-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Stack>Explore</Stack>
        <ArrowDropDownCircleOutlined sx={{ color: "accent.main" }} />
      </Button>
      <Menu
        id="explore-events-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "explore-events-button",
        }}
      >
        <List>
          {isEventLive && (
            <MenuItem
              key={liveEvent}
              onClick={() => {
                handleClose();
                navigate(`/live/${liveEvent}`);
              }}
            >
              Live
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleClose();
              navigate(`/live/${liveEvent}/throws`);
            }}
          >
            Throws
          </MenuItem>
          {/* TODO: Display past leaderboards and remove this logic */}
          {currentEvent?.event === liveEvent && (
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(`/live/leaderboards`);
              }}
            >
              Leaderboards
            </MenuItem>
          )}
        </List>
      </Menu>
    </>
  );
};
