import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import useScreen from "../hooks/useScreen";
import { TD_HQ_GEO } from "../components/consts";
import { TechDiscHQPin } from "../components/icons/TechDiscHQPin";

export type StoreMapProps = {
  children?: React.ReactNode;
};

export default function StoreMap(props: StoreMapProps) {
  const { children } = props;
  const { isMobile } = useScreen();

  return (
    <Map
      defaultCenter={{ lat: 38.9850629, lng: -94.71204 }}
      defaultZoom={isMobile ? 3 : 5}
      mapId="ca466929d5ef85bc"
      disableDefaultUI
      gestureHandling={"greedy"}
      clickableIcons={false}
    >
      <AdvancedMarker position={TD_HQ_GEO}>
        <TechDiscHQPin sx={{ position: "relative", width: "48px", height: "48px" }} />
      </AdvancedMarker>
      {children}
    </Map>
  );
}
