import * as React from 'react';
import { InteractionContext } from '../context/InteractionProvider';
import { HighlighContext } from '../context/HighlightProvider';
export const useInteractionItemProps = scope => {
  const {
    dispatch: dispatchInteraction
  } = React.useContext(InteractionContext);
  const {
    dispatch: dispatchHighlight
  } = React.useContext(HighlighContext);
  const getInteractionItemProps = data => {
    const onMouseEnter = () => {
      dispatchInteraction({
        type: 'enterItem',
        data
      });
      dispatchHighlight({
        type: 'enterItem',
        item: data,
        scope
      });
    };
    const onMouseLeave = () => {
      dispatchInteraction({
        type: 'leaveItem',
        data
      });
      dispatchHighlight({
        type: 'leaveItem',
        item: data
      });
    };
    return {
      onMouseEnter,
      onMouseLeave
    };
  };
  return getInteractionItemProps;
};
export const getIsHighlighted = (selectedItem, currentItem, highlightScope) => {
  if (!(highlightScope != null && highlightScope.highlighted) || highlightScope.highlighted === 'none' || selectedItem === null) {
    return false;
  }
  const isSeriesSelected = selectedItem.type === currentItem.type && selectedItem.seriesId === currentItem.seriesId;
  if (!isSeriesSelected) {
    return false;
  }
  if (highlightScope.highlighted === 'series') {
    return isSeriesSelected;
  }
  return selectedItem.dataIndex !== undefined && selectedItem.dataIndex === currentItem.dataIndex;
};
export const getIsFaded = (selectedItem, currentItem, highlightScope) => {
  if (!(highlightScope != null && highlightScope.faded) || highlightScope.faded === 'none' || selectedItem === null) {
    return false;
  }
  const isSeriesSelected = selectedItem.type === currentItem.type && selectedItem.seriesId === currentItem.seriesId;
  if (highlightScope.faded === 'series') {
    return isSeriesSelected && selectedItem.dataIndex !== currentItem.dataIndex;
  }
  if (highlightScope.faded === 'global') {
    if (!isSeriesSelected) {
      return true;
    }
    return selectedItem.dataIndex !== undefined && selectedItem.dataIndex !== currentItem.dataIndex;
  }
  return false;
};