import * as React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUserMetadata } from "../../hooks/useUserMetadata";
import { UserProfile, userProfileSchema } from "../../model/UserProfile";
import { storeLeaderboardUserMetadata, storeUserSettings } from "../../summaryUtils";
import UserProfileSettings from "./UserProfileSettings";
import { uploadUserProfilePhoto } from "./utils";
import useNotify from "../../hooks/useNotify";
import { SettingsPage } from "../../settings/SettingsPage";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import Title from "../../dashboard/Title";
import { UserMetadata } from "../../firebase/converters/leaderboardUserMetadata";
import { useGlobal } from "../providers/GlobalProvider";
import { useUser } from "../../hooks/useUser";
import { LeaderboardUserMetadata } from "../../model/leaderboard";

export function UserSettings() {
  const { userSettings, featureFlags } = useGlobal();
  const [{ trueUserId }] = useUser();
  const notify = useNotify();
  const [userMetadata] = useUserMetadata(trueUserId);
  const [profilePhoto, setProfilePhoto] = React.useState<File | null | undefined>(undefined);

  const methods = useForm<UserProfile>({
    resolver: yupResolver(userProfileSchema),
    defaultValues: userMetadata
      ? {
          ...userMetadata,
          preferredMeasurementSystem: userSettings?.preferredMeasurementSystem ?? "us",
        }
      : {
          name: "",
          photo: "",
          pdgaNumber: "",
          preferredMeasurementSystem: "us",
        },
  });

  const handleDeletePhoto = () => {
    methods.setValue("photo", "", { shouldDirty: true });
    setProfilePhoto(null);
  };

  const handleSelectPhoto = (photo: File) => {
    methods.setValue("photo", URL.createObjectURL(photo), { shouldDirty: true });
    setProfilePhoto(photo);
  };

  const handleReset = React.useCallback(() => {
    if (userMetadata && userSettings) {
      methods.reset({
        ...userMetadata,
        preferredMeasurementSystem: userSettings.preferredMeasurementSystem,
      });
    }
  }, [userMetadata, userSettings, methods]);

  React.useEffect(() => {
    handleReset();
  }, [handleReset]);

  const handleSave = React.useCallback(
    async (profileFields: UserProfile) => {
      if (trueUserId) {
        const { preferredMeasurementSystem, name, ...userMetadata } = profileFields;
        try {
          // If there is a profile photo, upload it and get the url
          const photo = profilePhoto ? await uploadUserProfilePhoto(profilePhoto) : undefined;
          // Create the user profile
          const updatedUserMetadata: LeaderboardUserMetadata = {
            ...userMetadata,
            displayName: name,
            // If there is a photo, use the 200px version
            photo: photo?.[200],
          };
          // Save the user profile
          await storeLeaderboardUserMetadata(trueUserId, updatedUserMetadata);
          await storeUserSettings(trueUserId, { preferredMeasurementSystem });
          notify("success", "Settings saved successfully.");

          // Reset the form to the new values
          methods.reset({}, { keepValues: true });
        } catch (e) {
          notify("error", "There was a problem updating your settings, please try again.");
        }
      }
    },
    [trueUserId, profilePhoto, methods, notify],
  );
  const settingsTabs = [
    {
      label: "Profile",
      content: (
        <UserProfileSettings onSelectPhoto={handleSelectPhoto} onDeletePhoto={handleDeletePhoto} />
      ),
    },
  ];

  if (featureFlags.metric_enabled) {
    settingsTabs.push({
      label: "Preferences",
      content: <UserPreferences methods={methods} />,
    });
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <SettingsPage id="user-settings" title="Profile" tabs={settingsTabs} methods={methods} />
      </form>
    </FormProvider>
  );
}
function UserPreferences(props: {
  methods: ReturnType<typeof useForm<UserProfile>>;
}): React.ReactNode {
  const { methods } = props;
  return (
    <Stack gap={3}>
      <Controller
        name="preferredMeasurementSystem"
        control={methods.control}
        render={({ field }) => (
          <Stack gap={0.5}>
            <Title variant="secondary">Measurement System</Title>
            <RadioGroup {...field} sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel value="us" control={<Radio />} label="US (Imperial)" />
              <FormControlLabel value="metric" control={<Radio />} label="Metric" />
            </RadioGroup>
          </Stack>
        )}
      />
    </Stack>
  );
}
