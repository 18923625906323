import { useMemo } from "react";
import { MetricHeader, Table } from "./Table";
import { LIVE_USER_ID } from "../../dashboard/liveEvents";
import { createColumnHelper } from "@tanstack/react-table";
import { formatNumber } from "../../utils/measurement";
import { Paper, Stack } from "@mui/material";
import { Heavy, Strong } from "../Text";
import { getDisplayNamePlaceholder } from "../leaderboard/fakeNames";
import { useEstimatedRank } from "../leaderboard/leaderboardUtils";
import { LeaderboardEntry, LeaderboardEntryWithId } from "../../model/leaderboard";

const columnHelper = createColumnHelper<LeaderboardEntryWithId>();

function LeaderboardTable({ data }: { data: LeaderboardEntryWithId[] }) {
  const columns = useMemo(() => {
    const columns: any[] = [
      columnHelper.accessor("displayName", {
        minSize: 100,
        size: 150,
        header: () => "Name",
        cell: ({ row, getValue }) =>
          getValue() ?? getDisplayNamePlaceholder(row.original.toUserId ?? row.original.id),
        enableGrouping: true,
      }),
      columnHelper.accessor("bestSpeedMph", {
        minSize: 100,
        size: 150,
        meta: { align: "right" },
        header: () => <MetricHeader label="Speed" unit="MPH" />,
        cell: ({ getValue }) => formatNumber(getValue(), { maximumFractionDigits: 1 }),
        enableGrouping: true,
      }),
      columnHelper.accessor("bestSpinRpm", {
        minSize: 100,
        size: 150,
        meta: { align: "right" },
        header: () => <MetricHeader label="Spin" unit="RPM" />,
        cell: ({ getValue }) => formatNumber(getValue(), { maximumFractionDigits: 0 }),
        enableGrouping: true,
      }),
      columnHelper.accessor("bestDistanceFeet", {
        minSize: 100,
        size: 150,
        meta: { align: "right" },
        header: () => <MetricHeader label="Distance" unit="FT" />,
        cell: ({ getValue }) =>
          getValue() && Number.isNaN(parseFloat(getValue()))
            ? "—"
            : formatNumber(getValue(), { maximumFractionDigits: 1 }),
        enableGrouping: true,
      }),
      columnHelper.display({
        minSize: 100,
        size: 200,
        id: "estimatedSpeed",
        meta: { align: "right" },
        header: () => <MetricHeader label="Speed Rank" unit="Estimate" />,
        cell: ({ row }) => <EstimatedRankColumn row={row.original} metric="Speed" />,
        enableGrouping: true,
      }),
      columnHelper.display({
        minSize: 100,
        size: 200,
        id: "estimatedSpin",
        meta: { align: "right" },
        header: () => <MetricHeader label="Spin Rank" unit="Estimate" />,
        cell: ({ row }) => <EstimatedRankColumn row={row.original} metric="Spin" />,
        enableGrouping: true,
      }),
      columnHelper.display({
        minSize: 100,
        size: 200,
        id: "estimatedDistance",
        meta: { align: "right" },
        header: () => <MetricHeader label="Distance Rank" unit="Estimate" />,
        cell: ({ row }) => <EstimatedRankColumn row={row.original} metric="Distance" />,
        enableGrouping: true,
      }),

      // columnHelper.accessor("hyzerAngle", {
      //   meta: { align: "right" },
      //   header: () => <MetricHeader label="Hyzer" unit="DEG" />,
      //   cell: ({ getValue }) => formatNumber(getValue(), { maximumFractionDigits: 1 }),
      // }),
      // columnHelper.accessor("noseAngle", {
      //   meta: { align: "right" },
      //   header: () => <MetricHeader label="Nose" unit="DEG" />,
      //   cell: ({ getValue }) => formatNumber(getValue(), { maximumFractionDigits: 1 }),
      // }),
      // columnHelper.accessor("launchAngle", {
      //   meta: { align: "right" },
      //   header: () => <MetricHeader label="Launch" unit="DEG" />,
      //   cell: ({ getValue }) => formatNumber(getValue(), { maximumFractionDigits: 1 }),
      // }),
      // columnHelper.accessor("wobble", {
      //   meta: { align: "right" },
      //   header: () => <MetricHeader label="Wobble" unit="DEG" />,
      //   cell: ({ getValue }) => formatNumber(getValue(), { maximumFractionDigits: 1 }),
      // }),
    ];
    return columns;
  }, []);

  return (
    <Paper component={Stack} sx={{ p: 1 }}>
      {data && data.length > 0 ? (
        <Table id="live-analysis-table" data={data.slice(0, 10)} columns={columns} />
      ) : (
        <Stack justifyContent="center" alignItems="center">
          No throws yet!
        </Stack>
      )}
    </Paper>
  );
}

export default LeaderboardTable;

export const EstimatedRankColumn = ({
  row,
  metric,
}: {
  row: LeaderboardEntry;
  metric: "Speed" | "Spin" | "Distance";
}) => {
  const estimatedRank = useEstimatedRank(row, LIVE_USER_ID);
  const rank =
    estimatedRank?.[`userBest${metric}Rank`] ?? estimatedRank?.[`globalBest${metric}Rank`];
  const isTop10 = rank && rank <= 10;
  const Text = isTop10 ? Heavy : Strong;
  return rank ? (
    <Text size={isTop10 ? 16 : 14} color={isTop10 ? "primary.main" : "inherit"}>
      {getOrdinalNumber(rank)}
    </Text>
  ) : (
    "—"
  );
};

function getOrdinalNumber(number: number) {
  const suffixes = ["th", "st", "nd", "rd"];
  const v = number % 100;
  return (
    <span>
      {number}
      <sup>{suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]}</sup>
    </span>
  );
}
