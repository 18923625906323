"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUnityCanvasId = void 0;
var react_1 = require("react");
/**
 * The canvas count is used to generate a unique Unity canvas ID.
 */
var unityCanvasCount = 0;
/**
 * The prefix used to generate a unique Unity canvas ID.
 */
var unityCanvasIdPrefix = "react-unity-webgl-canvas";
/**
 * Generates a unique Unity canvas ID. This is used internally by Unity since
 * version 2021.2 to identify the canvas element in the DOM. This is not
 * documented in the Unity documentation, but it is used in the Unity source
 * code.
 * @returns A unique identifier for a Unity canvas element.
 */
var useUnityCanvasId = function (unityProps) {
    // If the user has provided a Unity canvas ID, then this value is returned.
    // This is useful for when the user wants to use a custom canvas ID.
    if (unityProps.id !== undefined) {
        return unityProps.id;
    }
    /**
     * A unique identifier for a Unity canvas element is memorized.
     */
    var unityCanvasId = (0, react_1.useMemo)(function () {
        // The Unity canvas ID is generated by concatenating the Unity canvas ID
        // prefix with the canvas count. Every time this value is requested, the
        // canvas count is incremented.
        return [unityCanvasIdPrefix, ++unityCanvasCount].join("-");
    }, []);
    return unityCanvasId;
};
exports.useUnityCanvasId = useUnityCanvasId;
