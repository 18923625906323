import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Timestamp, where } from "firebase/firestore";
import { useState } from "react";
import { computeAvgStat } from "../analysis/AnalyzeDashboard";
import { QueryBasedDashboard, type ThrowSummaryOrLoadingAndId } from "../../latestDashboard";
import { AnalysisSet, AnalysisType } from "../../model/throwSummary";
import { CoreStats } from "../../model/CoreStats";
import { getTrueUserId, getUserId, storeAnalysisSet, storeSummary } from "../../summaryUtils";
import { useUser } from "../../hooks/useUser";
import { useLocation } from "react-router-dom";
import { TestDriveStep } from "../routes/TestDrive";
import type { LeaderboardEntry } from "../../model/leaderboard";
import { useGlobal } from "../providers/GlobalProvider";
import { useNavigate } from "react-router-dom";
function computeLeaderboardEntry(
  throws: CoreStats[],
  avg: CoreStats,
  isLiveAccount?: boolean,
): LeaderboardEntry {
  return {
    throwCount: throws.length,
    avgSpeedMph: avg.speedMph,
    avgDistanceFeet: avg.estimatedFeet ?? null,
    avgSpinRpm: Math.abs(avg.rotPerSec * 60),

    bestSpeedMph: Math.max(...throws.map((t) => t.speedMph)),
    bestSpinRpm: Math.max(...throws.map((t) => Math.abs(t.rotPerSec * 60))),
    bestDistanceFeet: Math.max(...throws.map((t) => t.estimatedFeet as number)) ?? null,

    expireAt: isLiveAccount ? null : Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 7 * 2), // 2 weeks
    throwTime: avg.throwTime,
    userId: getTrueUserId(),
  };
}

export const TestDriveThrow = () => {
  const {
    isStore,
    isPremiumStore,
    isDeveloper: hasDeveloperFeatures,
    isLiveAccount,
    featureFlags,
  } = useGlobal();
  const { state } = useLocation();
  const { email, displayName, handedness, totalThrows } = state ?? {};
  const [{ user, userId }] = useUser();
  const [throwCount, setThrowCount] = useState(0);
  const [startTime] = useState(Date.now());
  const isLive = isLiveAccount && featureFlags.live_event;
  const navigate = useNavigate();

  function setThrows(throws: ThrowSummaryOrLoadingAndId[]) {
    setThrowCount(throws.length);
    if (throws.length >= totalThrows && (throws[0] as CoreStats)?.estimatedFeet) {
      const analysisId = (startTime / 1000).toFixed(0);
      // @ts-ignore
      const loadedThrows = throws.filter((t) => (t as CoreStats)?.estimatedFeet) as CoreStats[];
      const avg = computeAvgStat(loadedThrows);
      const analysisSet: AnalysisSet = {
        id: analysisId,
        ids: throws.map((t) => t.id),
        createTime: Timestamp.now(),
        type: AnalysisType.KIOSK,
        avg: avg,
      };

      if (email) {
        analysisSet.email = email;
      }

      const newLeaderboardEntry = computeLeaderboardEntry(loadedThrows, avg, isLive);

      if (isPremiumStore || isLive) {
        newLeaderboardEntry.premium = true;
      }
      if (displayName) {
        newLeaderboardEntry.displayName = displayName;
      }

      if (isStore || isPremiumStore || hasDeveloperFeatures || isLive) {
        analysisSet.leaderboardEntry = newLeaderboardEntry;
      }

      // // set email for each throw
      // throws.forEach((t) => {
      //   if (email) {
      //     storeSummary(getUserId(user), t.id, { email: email }).catch((e) => {
      //       console.error("Error storing summary", e);
      //     });
      //   }
      // });

      storeAnalysisSet(getUserId(user), analysisId, analysisSet).then(() => {
        navigate(`/test-drive/review/${analysisId}`, { state: { handedness } });
      });
    }
  }

  const timeFilter = where("throwTime", ">=", Timestamp.fromMillis(startTime));

  return (
    email && (
      <>
        <Box display="flex" gap={1} justifyContent={"center"} alignItems={"center"}>
          <Typography variant="h3" color={"primary"} align="center" fontWeight={600}>
            {throwCount}
          </Typography>
          <Typography variant="h5" color="grey.600" align="center" fontWeight={600}>
            / {totalThrows}
          </Typography>
        </Box>
        <QueryBasedDashboard
          userId={userId}
          setThrows={setThrows}
          addedFilters={[timeFilter]}
          noThrowComponent={
            <Typography variant="h6" color="grey.700" align="center">
              Ready for your first throw!
            </Typography>
          }
        />
      </>
    )
  );
};
