import { Timestamp } from "firebase/firestore";
import { Handedness } from "./UserSettings";

export enum ThrowStyle {
  Backhand = "BACKHAND",
  Forehand = "FOREHAND",
}

export enum ShotType {
  Hyzer = "HYZER",
  Anhyzer = "ANHYZER",
  Neutral = "NEUTRAL",
  Thumber = "THUMBER",
  Grenade = "GRENADE",
  Roller = "ROLLER",
  Tomahawk = "TOMAHAWK",
}

export interface ThrowSummary extends ThrowRecord {
  loading: boolean;
  originalSummary: ThrowSummary_FS;
  throwType?: string;
  bearing?: number | null;
  tags?: string[];
  notes?: string;
}

export interface ThrowRecord extends CoreMetrics {
  id: string;
  throwTime: Timestamp;
  handedness?: Handedness;
}

export interface CoreMetrics {
  speedMph?: number;
  speedKmh?: number;
  spinRpm?: number;
  noseAngle?: number;
  hyzerAngle?: number;
  launchAngle?: number;
  wobble?: number;
  advanceRatio?: number;
  distanceFeet?: number;
  distanceMeters?: number;
  upsideDown?: number;
}

export interface ThrowSummary_FS extends CoreStats_FS {
  id?: string;
  throwTime: Timestamp;
  // gamma range is [-pi, pi] and is the angle between the disc's velocity and the discs X axis at release
  // This means if gamma is 0 the disc is released with the leading edge as the X axis
  gamma?: number;
  wx?: number;
  wy?: number;
  // added for back compatibility
  primaryType?: string;
  estimatedFeet?: number;
  tags?: string[];
  notes?: string;
  handedness?: Handedness & string;
  bearing?: number | null;
}

export interface CoreStats_FS {
  hyzerAngle?: number;
  correctedHyzerAngle?: number;
  noseAngle?: number;
  correctedNoseAngle?: number;
  rotPerSec?: number; // Hz
  speedMph?: number;
  upsideDown?: boolean;
  uphillAngle?: number;
  offAxisDegrees?: number;
}
