"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../createSvgIcon"));

var _Menu = _interopRequireDefault(require("@rsuite/icon-font/lib/application/Menu"));

// Generated by script, don't edit it please.
var Menu = (0, _createSvgIcon["default"])({
  as: _Menu["default"],
  ariaLabel: 'menu',
  category: 'application',
  displayName: 'Menu'
});
var _default = Menu;
exports["default"] = _default;
module.exports = exports.default;