import { Alert, Container, Stack } from "@mui/material";
import Navbar from "./Navbar";
import { Box } from "@mui/system";
import { Footer } from "./Footer";
import {
  Link,
  Outlet,
  Params,
  RouteObject,
  ScrollRestoration,
  UIMatch,
  useMatches,
} from "react-router-dom";
import { useGlobal } from "../providers/GlobalProvider";
import UnauthenticatedBanner from "./UnauthenticatedBanner";
import { useEffect } from "react";
import { ErrorBoundary } from "../ErrorBoundary";
import { Medium, Text } from "../Text";
import { MotionConfig } from "framer-motion";

export default function Layout() {
  const { latestThrowId } = useGlobal();

  const matches: UIMatch<unknown, { title: string | ((params: Params) => string) }>[] =
    useMatches();

  useEffect(() => {
    const match = matches.reduceRight((acc, match) => {
      return acc ? acc : match.handle?.title;
    }) ?? { handle: { title: "" } };
    if (match?.handle?.title) {
      if (typeof match?.handle?.title === "function") {
        document.title = `${match?.handle?.title(match.params)} - TechDisc`;
      } else {
        document.title = `${match?.handle?.title} - TechDisc`;
      }
    }
  }, [matches]);

  return (
    <MotionConfig reducedMotion="user">
      <Navbar latestThrowId={latestThrowId} />
      <UnauthenticatedBanner />
      <ScrollRestoration />
      <Stack
        sx={{
          minHeight: "calc(100vh - 48px)",
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
          overflow: "auto",
          position: "relative",
        }}
        component="main"
      >
        <ErrorBoundary
          eventName="uncaught_error"
          fallback={
            <Container
              sx={{
                my: 8,
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
              maxWidth="sm"
            >
              <Medium size={36} color="secondary.main" spacing="dense" sx={{ lineHeight: "1.2" }}>
                We apologize for the inconvenience.
              </Medium>
              <Text size={20} color="secondary.dark" spacing="loose">
                An unexpected error has occurred.
              </Text>
              <Alert severity="success">A report has been sent to our team.</Alert>
              <Text size={16} color="secondary.dark" spacing="loose">
                Please try refreshing the page or{" "}
                <Box
                  component={Link}
                  to="https://shop.techdisc.com/pages/contact"
                  sx={{ display: "contents" }}
                >
                  contact support
                </Box>{" "}
                if the problem persists.
              </Text>
            </Container>
          }
        >
          <Outlet />
        </ErrorBoundary>
      </Stack>
      <Footer />
    </MotionConfig>
  );
}
