import { Handedness } from "../model/UserSettings";
import { CoreMetrics } from "../firebase/converters/analysisSet";
import { CoreStats } from "../model/CoreStats";
import { ThrowSummary } from "../model/throwSummary";
import { toBig } from "./math";
import { FlightPathParams } from "../dashboard/Simulate3D";
import * as THREE from "three";
import { TrackPoint } from "../trackUtils";
import { StockShot } from "../firebase/converters/stockShot";

export const getThrowStyle = (
  clockwiseRotation: boolean,
  handedness: Handedness = Handedness.RIGHT,
) => {
  if (!handedness) {
    return null;
  }
  if (clockwiseRotation) {
    return handedness === Handedness.LEFT ? "FH" : "BH";
  }
  return handedness === Handedness.LEFT ? "BH" : "FH";
};
export const getStockShotName = (stockShot: StockShot) => {
  return `${stockShot.throwStyle} ${stockShot.handedness} ${stockShot.shotType}`;
};
export const getVerboseThrowStyle = (
  clockwiseRotation: boolean,
  handedness: Handedness = Handedness.RIGHT,
) => {
  if (!handedness) {
    return null;
  }
  if (clockwiseRotation) {
    return handedness === Handedness.LEFT ? "Forehand" : "Backhand";
  }
  return handedness === Handedness.LEFT ? "Backhand" : "Forehand";
};

export const isBackhandSpin = (coreStats: CoreStats, handedness: Handedness = Handedness.RIGHT) => {
  return handedness === Handedness.LEFT
    ? // Lefties backhand spin is counter clockwise
      (coreStats?.rotPerSec ?? -1) < 0
    : // Righty backhand spin is clockwise
      (coreStats?.rotPerSec ?? 1) > 0;
};
export const isClockwiseSpin = (coreMetrics: CoreMetrics) => {
  return (coreMetrics?.rotPerSec ?? 1) > 0;
};

export const getThrowName = (
  coreMetrics: CoreMetrics,
  handedness: Handedness = Handedness.RIGHT,
): string | undefined => {
  if (!coreMetrics) {
    return;
  }
  const backhandSpin = isBackhandSpin(coreMetrics, handedness);
  if (!coreMetrics?.upsideDown) {
    return getHyzerType(coreMetrics);
  }
  return coreMetrics.hyzerAngle > 0
    ? backhandSpin
      ? "Thumber Grenade"
      : "Grenade"
    : backhandSpin
      ? "Thumber"
      : "Tomahawk";
};

const getHyzerType = (coreMetrics: CoreMetrics): string => {
  const hyzer = coreMetrics.hyzerAngle;
  if (hyzer > 45) {
    return "Spike Hyzer";
  } else if (hyzer > 5) {
    return "Hyzer";
  } else if (hyzer > -5) {
    return "Flat";
  } else if (hyzer > -30) {
    return "Anhyzer";
  } else {
    return "Roller";
  }
};

export const formatThrowSummary = (throwSummary?: ThrowSummary): ThrowSummary | undefined => {
  if (!throwSummary) {
    return;
  }
  return {
    ...throwSummary,
    speedKmh: toBig(throwSummary?.speedKmh?.toFixed(1)).toNumber(),
    speedMph: toBig(throwSummary?.speedMph?.toFixed(1)).toNumber(),
    hyzerAngle: toBig(throwSummary?.hyzerAngle?.toFixed(1)).toNumber(),
    spinRpm: toBig(throwSummary?.spinRpm?.toFixed(0)).toNumber(),
    advanceRatio: toBig(throwSummary?.advanceRatio?.toFixed(0)).toNumber(),
    noseAngle: toBig(throwSummary?.noseAngle?.toFixed(1)).toNumber(),
    launchAngle: toBig(throwSummary?.launchAngle?.toFixed(1)).toNumber(),
    wobble: toBig(throwSummary?.wobble?.toFixed(1)).toNumber(),
    distanceFeet: toBig(throwSummary?.distanceFeet?.toFixed(0)).toNumber(),
    distanceMeters: toBig(throwSummary?.distanceMeters?.toFixed(1)).toNumber(),
  };
};

export async function fetchFlight(data: FlightPathParams) {
  const response = await fetch("https://flight-api.techdisc.com/api/flight_path", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const track: FetchResult = await response.json();
  return convertToTrackPoints(track);
}

export const convertToTrackPoints = (track: FetchResult) => {
  // Flight path tool has Z point up instead of the traditional z down
  // This means we have to adjust the points by 180 degrees around X
  const adjust = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI);
  const result: TrackPoint[] = [];
  for (let i = 0; i < track.t.length; i++) {
    let rotation = new THREE.Quaternion(track.qx[i], track.qy[i], track.qz[i], track.qw[i]);
    const qGamma = new THREE.Quaternion().setFromAxisAngle(
      new THREE.Vector3(0, 0, 1),
      track.gamma[i],
    );
    rotation.multiply(qGamma);
    rotation = new THREE.Quaternion().multiplyQuaternions(rotation, adjust);
    rotation = adjust.clone().multiply(rotation);
    // rotation = new Quaternion().multiplyQuaternions(adjust, rotation);
    const time = track.t[i];
    const xyz = track.p[i];
    const position: THREE.Vector3 = new THREE.Vector3(xyz[0], xyz[1], xyz[2]);
    position.applyQuaternion(adjust);
    result.push({ position: position, time: time, q: rotation });
  }
  return result;
};

interface FetchResult {
  p: THREE.Vector3Tuple[];
  v: THREE.Vector3Tuple[];
  t: number[];
  qx: number[];
  qy: number[];
  qz: number[];
  qw: number[];
  gamma: number[];
}
