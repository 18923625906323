import _extends from "@babel/runtime/helpers/esm/extends";
function defaultizeValueFormatter(series, defaultValueFormatter) {
  const defaultizedSeries = {};
  Object.keys(series).forEach(seriesId => {
    var _series$seriesId$valu;
    defaultizedSeries[seriesId] = _extends({}, series[seriesId], {
      valueFormatter: (_series$seriesId$valu = series[seriesId].valueFormatter) != null ? _series$seriesId$valu : defaultValueFormatter
    });
  });
  return defaultizedSeries;
}
export default defaultizeValueFormatter;